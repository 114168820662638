.App {
  text-align: center;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
}

/*home*/

.home {
  width: 100%;
  margin: 0 auto;
}

.home-red-section-small-header {
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Clarity City";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.table-result {
  margin-top: 30px;
  border-collapse: collapse;
}

.home-red-section-button {
  border-radius: 40px;
  border: 1px solid var(--white-white-1, #fff);
  margin-top: 40px;
  padding: 17px 36px;
  background-color: transparent;
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Clarity City";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-red-section-paragraf {
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Clarity City";
  font-size: 32px;
  font-style: normal;
  width: 960px;
  font-weight: 700;
  margin: 0 auto;
  line-height: 42px;
}

.home-red-section {
  background-image: url("./assets/img/sport-background.png");
  background-size: cover;
  padding-top: 110px;
  background-repeat: no-repeat;
  max-width: 1920px;
  padding-bottom: 110px;
}

.home-section-small-header {
  color: var(--primary-primary, #e34646);
  text-align: center;
  font-family: "Clarity City Black";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.reference {
  width: 100%;
  margin: 0 auto;
}

.home-second-black-banner-elements {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  gap: 53px;
}

.home-second-black-banner-element-third-right {
  padding-top: 34px;
  padding-bottom: 15px;
  background-color: #da0000;
}

.foto-home {
  max-width: 224.95px;
  height: 317.62px;
}

.home-second-black-banner-element-second-left-one-textbox {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  padding-left: 89px;
  padding-top: 38px;
  padding-bottom: 15px;
  color: white;
  padding-right: 162px;
}

.home-second-black-banner-element-first-right {
  background-color: #da0000;
  padding: 10px;
}

.home-second-black-banner-element-first-right-second-paragraf {
  font-family: "Inter";
  font-style: normal;
  max-width: 500px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #ffffff;
}

.home-card-element-second-details {
  max-width: 260px;
  height: 301px;
  color: white;
  background: #282725;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 27px;
  padding-top: 38px;
}

.home-second-black-banner-element-children {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-flow: column;
}

.foto-home-1 {
  max-width: 224.95px;
  height: auto;
}

.home-foto-section {
  display: flex;
}

.home-second-black-banner-element-first-right-one-textbox {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  padding-left: 89px;
  color: white;
  padding-right: 135px;
}

.home-cirlce {
  position: relative;
  bottom: 16%;
}

.home-soccer-banner-right-paragraf {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.home-banner-second-retangle-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 128.02%;
  text-align: center;
  color: #ffffff;
}

.home-second-black-banner-element-first-right-one-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 12px;
  text-align: left;
  color: #ffffff;
}

.home-second-black-banner-element-first-right-second-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: black;
}

.home-second-black-banner-element-first-right-one-paragraf {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 128.02%;
  color: #ffffff;
  margin: 0 auto;
}

.photo-assemble {
  display: flex;
  gap: 20px;
  padding-left: 111px;
}

.home-second-black-banner-element-first-right-second-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  line-height: 128.02%;
  color: black;
  max-width: 500px;
}

.home-soccer-banner-left-paragraf {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}

.home-soccer-banner-left-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  max-width: 200px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.home-soccer-banner-left {
  background-color: #282725;
  width: 53%;
  padding-left: 319px;
  padding-top: 53px;
  color: white;
  padding-bottom: 106px;
  display: flex;
  flex-flow: column;
  gap: 38px;
  align-items: center;
}

.home-soccer-banner-right {
  width: 50%;
  background-color: #1f1e1d;
}

.home-soccer-banner {
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-height: 756px;
}

.home-soccer-banner-left-grid {
  display: grid;
  grid: auto / auto auto;
  justify-content: center;
  grid-column-gap: 17px;
  grid-row-gap: 63px;
  margin: 0 auto;
}

.home-soccer-banner-left-grid-element {
  max-width: 172px;
  height: 192px;
  padding: 10px;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  color: black;
}

.second-white-banner {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding-bottom: 243px;
  padding-top: 133px;
  max-width: 1920px;
}

.home-second-white-banner {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding-bottom: 99px;
  padding-top: 76px;
  max-width: 1920px;
}

.home-white-banner {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  padding-bottom: 56px;
  padding-top: 93px;
  max-width: 1920px;
}

.second-white-banner-content {
  display: flex;
  flex-flow: column;
  gap: 75px;
}

.home-second-white-banner-content {
  display: flex;
  flex-flow: column;
  gap: 75px;
}

.black-banner {
  max-width: 1920px;
  padding-top: 155px;
  padding-bottom: 246px;
  background: #282725;
}

.home-black-banner {
  max-width: 1920px;
  padding-top: 98px;
  padding-bottom: 84px;
  background-color: black;
}

.white-banner-soccer-talent {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 150.5%;
  text-align: center;
  color: #ff0000;
}

.home-white-banner-soccer-talent {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 150.5%;
  text-align: center;
  color: #ff0000;
}

.black-banner-text {
  color: white;
  padding-top: 155px;
}

.home-card-element-second-paragraf {
  max-width: 256px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150.5%;
  text-align: center;
  color: #ffffff;
}

.home-card-element-second-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150.5%;
  text-align: center;
  color: #ffffff;
}

.soccer-image {
  max-width: 833px;
  height: 465px;
}

.home-second-section{
  background: #51abe9;
  padding-top: 100px;
  padding-bottom: 100px;
}

.presentations-elements{
  display: grid;
    align-items: center;
    width: 100%;
    grid: auto / auto auto auto;
    justify-content: center;
    margin: 0 auto;
    gap: 114px;
}

.home-first-section-elements {
  /* display: grid;
  grid: auto / auto auto auto; */
  align-items: center;
  justify-content: center;
  gap: 130px;
  /* padding-right: 5%; */
  padding-top: 50px;
}

.home-first-section {
  background: orange;
  padding-top: 110px;
  padding-bottom: 114px;
}
.home-first-section-box-paragraf-red {
  color: red;
}

.home-second-section-paragraf {
  color: white;
  text-align: left;
  font-family: "Clarity City";
  font-size: 32px;
  font-style: normal;
  width: 595px;
  font-weight: 700;
  line-height: 42px;
  margin: 0 auto;
}

.home-third-section-elements {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  justify-content: center;
  padding-top: 50px;
}

.home-third-section-element {
  width: 300px;
  height: 170px;
  display: flex;
  flex-flow: row;
  gap: 35px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--white-white-1, #fff);
}

.home-icon-text-box {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 10px;
}

.home-icon-section-box-paragraf {
  color: #515353;
  text-align: left;
  font-family: "Clarity City";
  font-size: 16px;
  width: 236px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  line-height: 28px;
}

.home-third-section-paragraf {
  color: var(--white-white-8, #252828);
  text-align: center;
  width: 850px;
  font-family: "Clarity City Black";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin: 0 auto;
  line-height: 42px;
}

.home-first-section-box-paragraf {
  color: #515353;
  text-align: center;
  font-family: "Clarity City";
  font-size: 18px;
  max-width: 350px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  line-height: 28px;
}

.home-black-banner-box-inside {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 10px;
}

.home-black-banner-box {
  display: flex;
  flex-flow: column;
}

.home-first-section-paragraf {
  color: white;
  width: 595px;
  text-align: center;
  font-family: "Clarity City";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  padding-top: 12px;
  margin: 0 auto;
}

.home-black-banner-paragrafs {
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding-top: 28px;
}

.home-black-banner-list {
  color: var(--text, #959f9f);
  font-family: "Inter";
  font-size: 16px;
  text-align: left;
  flex-flow: column;
  gap: 10px;
  font-style: normal;
  padding: 0px;
  display: flex;
  list-style-type: circle;
  font-weight: 400;
  line-height: 26px;
}

.home-second-section-text {
  color: var(--primary-primary, #e34646);
  text-align: center;
  font-family: "Clarity City";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.home-first-section-text {
  color: var(--white-white-8, #252828);
  text-align: center;
  font-family: "Clarity City";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

.home-first-section-element {
  max-width: 313px;
  height: 245px;
  flex-shrink: 0;
  border-radius: 16px;
}

.react-pdf__Page__textContent{
    display: none;
}
.react-pdf__Page__annotations{
  display: none;
}

.template{
  background-image: linear-gradient(rgb(24, 191, 242), white);
  width: 100%;
}

input[type=text], select {
  width: 334px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.files{
  background-color: #51abe9;
    padding: 12px;
    margin-top: 20px;
    width: 335px;
    border-radius: 0px;
}

.home-black-banner-text {
  max-width: 584px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.02%;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
}

.home-small-header {
  color:white;
  text-align: center;
  font-family: "poppins";
  font-size: 16px;
  font-style: normal;
  margin: 0px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.home-button-check-special {
  width: 267px;
  height: 54px;
  border: none;
  background: #ff0000;
  box-shadow: 0px 0px 24px #ff0000;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.home-banner-sport-predictor-left-box {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  padding-top: 70px;
  margin: 0 132px 0 132px;
  width: 100%;
  max-width: 480px;
  gap: 25px;
}
.home-banner-sport-predictor {
  display: flex;
  padding-bottom: 110px;
  background-color: black;
  padding-top: 110px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.home-laptop-paragraf {
  text-align: left;
  margin: 0px;
  width: 595px;
  color: var(--text, #959f9f);
  font-family: "Clarity City";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.home-laptop-banner-header-text {
  color: var(--white-white-1, #fff);
  font-family: "Clarity City";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin: 0px;
  width: 630px;
  text-align: left;
}

.home-laptop-banner-text {
  display: flex;
  flex-flow: column;
  max-width: 600px;
  gap: 20px;
  align-items: baseline;
}

.home-banner-sport-predictor-main {
  display: flex;
  padding-top: 80px;
  flex-flow: row;
  align-items: center;
  gap: 65px;
  justify-content: center;
}

.home-blog-element {
  width: 300px;
  height: 574px;
  border-radius: 12px;
  background: var(--white-white-8, #252828);
}

.home-blog-header {
  color: var(--white-white-1, #fff);
  font-family: "Clarity City";
  font-size: 20px;
  font-style: normal;
  margin: 0px;
  width: 260px;
  padding-top: 8px;
  font-weight: 700;
  line-height: 30px;
}

.home-blog-section-elements {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  gap: 30px;
  justify-content: center;
  padding-top: 17px;
}

.home-blog-paragraf {
  color: var(--white-white-3, #b0b6b6);
  font-family: "Clarity City";
  font-size: 16px;
  margin: 0px;
  padding-top: 12px;
  font-style: normal;
  font-weight: 400;
  width: 260px;
  line-height: 26px;
}

.home-blog-text-box {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  text-align: left;
  padding-left: 20px;
}

.home-banner-sport-predictor-header {
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Clarity City";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin: 0px;
}

.export-btn {
  padding: 8px 24px;
  border-radius: 50px;
  background: var(--primary-primary, #e34646);
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Inter";
  height: 50px;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-white-section-button {
  border-radius: 0px;
  background: #51abe9;
  padding: 13px 30px;
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  border: none;
  margin-top: 53px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.quiz-question-text{
  font-size: 20px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.home-white-section-paragraf {
  color: var(--white-white-8, #252828);
  text-align: center;
  font-family: "Clarity City";
  font-size: 32px;
  font-style: normal;
  margin: 0 auto;
  width: 1290px;
  font-weight: 700;
  line-height: 42px;
}

.home-white-section-small-header {
  color: #51abe9;
  text-align: center;
  font-family: "poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Wybierz zdjęcie';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.home-white-section {
  background-image: url("./assets/img/white-background.png");
  background-size: cover;
  padding-top: 50px;
  background-repeat: no-repeat;
  max-width: 1920px;
  /* padding-bottom: 110px; */
}

.home-sport-section-text {
  color: white;
  text-align: center;
  font-family: "Clarity City";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

.home-next-section-element {
  width: 300px;
  height: 170px;
  display: flex;
  flex-flow: row;
  gap: 35px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
}

.home-banner-sport-predictor-paragraf {
  color: #97a2a9;
  text-align: center;
  font-family: "Clarity City";
  font-size: 18px;
  margin: 0 auto;
  width: 343px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.banner-sport-predictor-right {
  display: block;
}

.home-banner-sport-predictor-left {
  width: 70%;
  justify-content: center;
  display: flex;
}

.home-button-check {
  width: 267px;
  height: 54px;
  border: none;
  background: #ff0000;
  box-shadow: 0px 0px 24px #ff0000;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.home-first-section-header-span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  max-width: 527px;
  line-height: 38.73px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
}

.home-banner {
  padding-top: 0px;
  text-align: -webkit-center;
  /* background-image: url("./assets/img/Dashboard.png");
  background-size: 870px 592px; */
  background-color: #81d742;
  color: white;
  width: 100%;
}

.banner-image {
  /* padding-top: 115px; */
}

.home-body-card-second-elements {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 52px;
}

.home-last-banner {
  text-align: -webkit-center;
  background-image: url("./assets/img/BG3.png");
  color: white;
  width: 100%;
  background-size: auto;
  background-repeat: no-repeat;
}

.home-last-banner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 55px;
  padding-bottom: 73px;
  gap: 103px;
}

.home-banner-text {
  padding-top: 100px;
  display: flex;
  flex-flow: column;
  max-width: 600px;
  margin: 0 auto;
  gap: 20px;
  padding-bottom: 100px;
  align-items: center;
}

.home-banner-header {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  margin-right: 0;
  font-style: italic;
}

.home-banner-paragraf {
  text-align: left;
  color:white;
  margin: 0px;
  width: 595px;
  font-family: "Clarity City";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.home-banner-header-text {
  font-family: "poppins";
  font-size: 44px;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  margin: 0px;
  width: 630px;
  text-align: left;
}

.button-home-predictor {
  width: 182px;
  height: 54px;
  background: #ff0000;
  color: white;
  box-shadow: 0px 0px 11px #100202;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.button-home-calculate {
  width: 243px;
  height: 52px;
  background: #81d742;
  color: white;
  box-shadow: 0px 0px 11px #100202;
  border-radius: 60px;
  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  text-align: center;
}
.home-line {
  color: white;
  width: 80px;
  margin-left: 3px;
}

.home-body-card-elements-box {
  display: flex;
  flex-flow: column;
  gap: 30px;
  width: 100%;
}

.home-body-card-elements {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 52px;
}

.home-body-card-element {
  font-size: 17px;
  color: white;
  padding: 10px;
}

.home-card-element-first-red {
  width: 260px;
  height: 45px;
  background: #ff0000;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 150.5%;
  text-align: center;
  color: #ffffff;
}

.home-card-element-second {
  max-width: 189px;
  min-height: 230px;
  color: white;
  background: #282725;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 21px;
  padding: 10px;
}

.home-card-element-third {
  max-width: 171px;
  min-height: 230px;
  color: white;
  background: #282725;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 21px;
  padding: 10px;
}

/*header*/

.search-bar {
  width: 207px;
  height: 59px;
  background: #ffffff;
  border-radius: 47px;
  border: none;
  padding-left: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  text-transform: capitalize;
  color: #adadad;
}

.icon-search {
  position: absolute;
  top: 7%;
  right: 62%;
}

.boy {
  display: block;
  width: 191.24px;
  height: 258.14px;
  margin-bottom: 193px;
  padding-right: 40px;
}
.girl {
  display: block;
  max-height: 353.98px;
  padding-right: 0px;
}

.user-input-change {
  border: none;
  background: whitesmoke;
  border: 1px solid black;
  text-align: center;
}

.user-data-core {
  text-align: left;
  padding-left: 200px;
  padding-right: 237px;
}

.user-data-elements {
  display: flex;
  flex-flow: column;
  gap: 0px;
  margin-top: 54px;
}

.user-data-table {
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-flow: column;
  padding-top: 40px;
  margin-top: 40px;
  padding-left: 46px;
  padding-right: 73px;
}

.arrow-data {
  width: 20%;
}

.user-data-table-cell-special {
  display: grid;
  justify-content: center;
  gap: 47px;
  width: 100%;
  padding: 11px;
  height: 57px;
  background: #f5f5f5;
  border-radius: 14px;
}

.home-clipart {
  display: none;
}

.size {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  background: whitesmoke;
  gap: 45px;
}

.user-data-table-cell {
  width: 100%;
  height: 57px;
  background: #f5f5f5;
  border-radius: 14px;
  gap: 10px;
  display: grid;
}

.user-data-element-white {
  width: 179px;
  color: black;
  padding: 1px;
  background: #ffffff;
  box-shadow: inset 0px 4px 4px rgb(0 0 0 / 3);
}

.user-core {
  display: flex;
  width: 100%;
  flex-flow: row;
  gap: 48px;
  background-color: #252828;
}

.user-navigation-elements {
  display: flex;
  align-items: baseline;
  flex-flow: column;
}

.user-grid {
  display: grid;
  grid: auto / auto auto auto;
  grid-column-gap: 24px;
  grid-row-gap: 20px;
  align-items: flex-end;
  padding-left: 24px;
  padding-right: 24px;
}

.user-field {
  width: 336px;
  height: 56px;
  color: var(--white-white-2, #dfe2e2);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  padding-left: 20px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 100px;
  border: 1.5px solid var(--white-white-7, #3a3d3d);
  background: #252828;
}

.user-photo {
  width: 100px;
  padding-top: 24px;
  padding-left: 24px;
  height: 100px;
}

.user-navigation-text {
  color: var(--white-white-3, #b0b6b6);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.user-right-box {
  display: flex;
  width: 1104px;
  flex-flow: column;
  height: 331px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--white-white-7, #3a3d3d);
  background: var(--white-white-8, #252828);
  gap: 24px;
}

.user-right-box-description {
  display: flex;
  flex-flow: column;
  align-items: baseline;
}

.user-right-box-description-text {
  color: var(--white-white-2, #dfe2e2);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  margin: 0px;
  font-weight: 500;
  line-height: 22px;
}

.user-right-box-description-number {
  color: var(--white-white-2, #dfe2e2);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  margin: 0px;
  font-weight: 500;
  line-height: 22px;
}

.user-right-box-element {
  width: 258px;
  height: 75px;
  /* border-radius: 12px; */
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  /* border: 1px solid var(--white-white-7, #3a3d3d); */
  /* background: #2b2e2e; */
}

.user-navigation-icon-box {
  padding-top: 45px;
  display: flex;
  flex-flow: column;
  gap: 20px;
}

.user-navigation-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;
}

.user-right-header {
  text-align: left;
  color: var(--white-white-1, #fff);
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}

.user-right {
  width: 1596px;
  border-radius: 0px;
  background: var(--white-white-8, #252828);
  height: 971px;
  display: flex;
  flex-flow: column;
  align-items: baseline;
}

.user-navigation {
  background-color: #3a3d3d;
  padding-top: 20px;
  width: 240px;
  height: 971px;
  border-radius: 8px 0px 0px 8px;
  color: white;
  align-items: center;
  display: flex;
  flex-flow: column;
}

.size-element {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.mission-text-paragraf {
  text-align: justify;
  max-width: 1000px;
}

.tablets {
  max-width: 36%;
}

.logo_link {
  display: block;
  max-width: 100%;
}

.logo-neon {
  max-width: 25%;
}

.products {
  width: 100%;
  color: white;
  background: #282725;
}

/* td {
  max-width: 106px;
  text-align: center;
  padding: 10px;
  border: 1px solid black;
} */

.create-form {
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding: 20px;
}

.register-personal-data {
  display: grid;
  grid: auto auto / auto auto;
  padding-top: 43px;
  grid-column-gap: 171px;
  grid-row-gap: 20px;
}

.register-data-type {
  padding-top: 4px;
}

.button-create-account-new {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  width: 208.08px;
  height: 37px;
  line-height: 22px;
  position: relative;
  border: none;
  top: 32px;
  text-align: center;
  color: #ffffff;
  right: 26px;
  background: red;
  border-radius: 67px;
}

.button-create-account {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  width: 246.08px;
  height: 44px;
  line-height: 22px;
  position: relative;
  border: none;
  top: 145px;
  text-align: center;
  color: #ffffff;
  right: 0px;
  background: red;
  border-radius: 67px;
}

.register-personal-data-box {
  max-width: 399px;
}

.login {
  display: flex;
  padding-top: 158px;
  padding-bottom: 158px;
  gap: 300px;
}

.login-box {
  width: 296px;
  height: 294px;
  left: 542px;
  top: 306px;
  background: #282725;
}

.login-box-placeholder {
  width: 296px;
  padding: 15px;
  height: 54px;
  background: #04bc4c;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}

.login-box-container {
  padding-top: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.home-computer {
  max-width: 100%;
  position: relative;
  left: 75%;
}

.arrow {
  width: 1471px;
  position: absolute;
  right: 12%;
  top: 116px;
}

.link-login {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.login-box-button {
  background: #282725;
  border: 1px solid green;
  max-width: 171px;
  padding: 20px;
  margin-top: 0px;
  color: white;
}

.more-box {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.clipart-box-element-paragraf {
  max-width: 200px;
}

.clipart-box-element-header {
  text-align: -webkit-center;
}

.clipart-box {
  display: grid;
  grid: auto / auto auto auto auto;
  grid-column-gap: 63px;
  grid-row-gap: 74px;
  justify-content: center;
  align-items: baseline;
}

.home-banner-icon {
  padding-top: 122px;
  padding-bottom: 122px;
}

.white-banner-header {
  margin-bottom: 8px;
  text-align: -webkit-center;
}

.white-banner-header-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  max-width: 396px;
  line-height: 26px;
  color: #282725;
}

.white-banner-paragraf {
  max-width: 471px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}

.home-third-section-special {
  color: #04bc4c;
}

.home-second-black-banner-element-second-left {
  background-color: white;
}

.home-banner-retangle-bo {
  display: flex;
  justify-content: center;
}

.home-banner-retangle-box-text {
  position: absolute;
  top: 20%;
  left: 17%;
}

.home-banner-retangle {
  position: relative;
  color: white;
  background-color: black;
}

.home-banner-second-retangle {
  padding-top: 55px;
  padding-bottom: 55px;
  color: white;
  background-color: #1f1e1d;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.home-last-banner-box-right-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 0px;
  text-align: center;
  color: #ffffff;
}

.home-last-banner-box-right-special-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 24px;
  text-align: center;
  color: #ff0000;
}

.home-banner-second-retangle-element {
  display: flex;
  margin: 0 auto;
  gap: 63px;
  align-items: flex-end;
}

.home-banner-second-retangle-element-left {
  width: 50%;
  display: flex;
  flex-flow: column;
  align-items: baseline;
  padding-left: 380px;
}

.home-second-black-banner-element-fourth-left {
  background-color: #da0000;
  color: white;
  max-width: 320px;
  padding: 33px;
}

.home-second-black-banner {
  background-color: #1f1e1d;
  padding-top: 44px;
  padding-bottom: 44px;
  position: relative;
}

.home-second-black-banner-element-first {
  display: flex;
  justify-content: center;
}

.register-personal-data-line {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #e3e3e3;
}

#gauge-chart1 {
  width: 72%;
}

.user-field-profile {
  width: 330px;
  height: 36px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

svg {
  width: 100%;
  margin: 0 auto;
}

input[type="number"] {
  width: 344px;
  height: 36px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

.login-button {
  display: flex;
  font-family: "poppins";
  flex-direction: column;
  cursor: pointer;
  color: white;
  border-radius: 50px;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color:transparent;
}

.sub-first-section {
  background-color: #161818;
  padding-bottom: 110px;
  padding-top: 110px;
}

.history-banner {
  padding-top: 120px;
  background-color: black;
  padding-bottom: 120px;
}

.history-banner-container {
  display: flex;
  width: 100%;
  background-image: url("./assets/img/Ellipse\ 1.png");
  gap: 60px;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}

.history-banner-paragraf {
  color: var(--text, #959f9f);
  text-align: center;
  font-family: "Clarity City";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  max-width: 672px;
  margin: 0 auto;
  line-height: 30px;
}

.history-header {
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Clarity City";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
}

.history-first-section-paragraf {
  color: var(--text, #959f9f);
  text-align: center;
  font-family: "Clarity City";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.history-banner-header-text {
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Clarity City";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.history {
  width: 100%;
  margin: 0 auto;
  /* background-color: black; */
}

.register-button {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'poppins';
  cursor: pointer;
  border-radius: 50px;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color:transparent;
}

.home-first-section-icon {
  padding-top: 50px;
}

.language-side {
  display: flex;
  align-items: center;
  gap: 10px;
}

select {
  background-color: transparent;
  color:black;
  font-size:15px;
}

.language-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-card-element-second-special {
  max-width: 247px;
  max-height: 374px;
  background: #282725;
  box-shadow: 0px 4px 4px rgb(255 0 0 / 30%);
  border-radius: 10px;
  display: flex;
  padding: 14px;
  flex-flow: column;
  align-items: center;
  gap: 20px;
}

.home-card-element-first-red {
  max-width: 189.4px;
  max-height: 29.67px;
  background: #ff0000;
  border-radius: 10px;
  position: relative;
  padding: 5px;
  top: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150.5%;
  text-align: center;
  color: #ffffff;
}

.home-card-element-second-red {
  max-width: 171.4px;
  max-height: 29.67px;
  background: #ff0000;
  border-radius: 10px;
  position: relative;
  padding: 5px;
  top: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150.5%;
  text-align: center;
  color: #ffffff;
}

.home-card-element-first-red-special {
  max-width: 247px;
  max-height: 42px;
  background: #ff0000;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  top: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 150.5%;
  text-align: center;
  color: #ffffff;
}

.children {
  max-width: 319px;
}

.home-body-card-element-special {
  font-size: 17px;
  color: white;
  padding: 10px;
}

.home-last-section-first-row-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 132%;
  max-width: 285px;
  text-align: justify;
  color: #000000;
}

.home-last-section-first-row-header {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 132%;
  color: #000000;
}

.home-last-section-first-row-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 132%;
  max-width: 285px;
  text-align: justify;
  color: #000000;
}

.header-mission {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  text-align: left;
  font-size: 32px;
  line-height: 39px;
  color: #ff0000;
}

.home-header-mission {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  text-align: left;
  font-size: 20px;
  line-height: 39px;
  color: #ff0000;
}

.block-text-banner {
  padding-top: 256px;
  display: flex;
  align-items: center;
}

.home-block-text-banner {
  padding-top: 116px;
  display: flex;
  align-items: center;
}

.home-paragraf-mission {
  max-width: 674px;
  height: 137px;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 148.02%;
  color: #282725;
}

.sport-second-text-box {
  text-align: justify;
  padding-right: 387px;
}

.home-first-section-right {
  display: flex;
  flex-flow: column;
  align-items: baseline;
}

.home-first-section-left {
  width: 31%;
  padding: 115px;
  max-height: 484px;
  background-color: #ff0000;
}

.home-offer-first-section {
  background-color: #151515;
  width: 100%;
  display: flex;
  gap: 150px;
  align-items: center;
}

.home-soccer-banner-right-box {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.footer-section-inside-list-1 {
  color: white;
  font-family: "Clarity City";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.footer-section-inside-list {
  margin: 0px;
  padding: 20px 0 0 0;
  display: flex;
  gap: 10px;
  text-align: left;
  flex-flow: column;
}

.footer-header-special {
  color: var(--white, #fff);
  font-family: "Clarity City";
  font-size: 14px;
  opacity: 0.6000000238418579;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.footer-section-inside-element-header {
  margin: 0px;
  color: var(--white-white-1, #fff);
  font-family: "Clarity City";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.footer-section-inside-element {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  color: white;
}

.sub-header {
  text-align: left;
  /* padding-left: 30px; */
  color: var(--white-white-8, #252828);
  font-family: "Clarity City";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.sub-btn {
  padding-top: 40px;
}

.sub-element-btn {
  width: 350px;
  padding: 17px 36px;
  cursor: pointer;
  border-radius: 40px;
  color: var(--white-white-1, #fff);
  text-align: center;
  font-family: "Clarity City";
  border: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--primary-primary, #e34646);
}

.sub-elements {
  display: flex;
  width: 100%;
  flex-flow: row;
  gap: 30px;
  padding-top: 60px;
  align-items: center;
  justify-content: center;
}

.sub-element {
  width: 410px;
  border-radius: 16px;
  display: flex;
  flex-flow: column;
  padding-left: 30px;
  align-items: baseline;
  background: var(--white-white-1, #fff);
  height: 873px;
}

.footer-section-inside {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  padding-bottom: 100px;
}

.footer-left-paragraf {
  color: var(--text, #959f9f);
  font-family: "Clarity City";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0px;
  padding-top: 20px;
  width: 300px;
  text-align: left;
}

.home-card-element-second-special-text {
  max-width: 335px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150.5%;
  text-align: center;
  color: #ffffff;
}

.sign-clipart {
  width: 40%;
  padding-top: 21px;
}

.science-clipart {
  width: 30%;
  padding-top: 30px;
}

.science-clipart-special {
  width: 40%;
  padding-top: 33px;
}

.home-paragraf {
  display: flex;
  align-items: baseline;
  gap: 259px;
}

.boy-img {
  margin-right: 436px;
}

.calculator {
  background-color: #252828;
  padding-bottom: 50px;
}

.calculator-btn {
  border-radius: 50px;
  background: var(--primary-primary, #e34646);
  padding: 16px 30px;
  color: var(--white-white-1, #fff);
  text-align: center;
  cursor: pointer;
  width: 336px;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.calculator-button {
  border-radius: 50px;
  background: var(--primary-primary, #e34646);
  padding: 16px 30px;
  color: var(--white-white-1, #fff);
  text-align: center;
  cursor: pointer;
  width: 327px;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.calculator-first-box {
  display: flex;
  flex-flow: column;
  align-items: baseline;
}

.calculator-grid {
  display: grid;
  gap: 20px;
}

.calculator-measure-text {
  color: var(--white-white-1, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

input[type="text"] {
  /* width: 248px; */
  width: 334px;
  height: 36px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  /* border-radius: 25px; */
  box-sizing: border-box;
}

.calculator-boxes {
  display: grid;
  align-items: baseline;
  grid: auto / auto auto;
  padding-top: 50px;
  justify-content: center;
  padding-bottom: 50px;
  /* background-color: #252828; */
  gap: 60px;
}

.home-banner{
  background-image: linear-gradient(rgb(24, 191, 242), white);
}

/* .home-first-section{
  background-image: linear-gradient(rgb(36, 199, 36), white);
} */

label {
  color: var(--white-white-4, black);
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  flex-flow: column;
  display: flex;
  text-align: left;
  font-weight: 500;
  line-height: normal;
}

.user-grid-calculator {
  display: flex;
  flex-flow: column;
  text-align: left;
}

.sub-text {
  color: var(--white-white-3, #b0b6b6);
  font-family: "Inter";
  font-size: 14px;
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.sub-small-text {
  color: var(--white-white-2, #dfe2e2);
  font-family: "Inter";
  font-size: 16px;
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.sub-form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  gap: 20px;
}

.sub-grid {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  width: 556px;
  padding-left: 20px;
}

.sub-right-box {
  display: flex;
  width: 1104px;
  flex-flow: column;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--white-white-7, #3a3d3d);
  background: var(--white-white-8, #252828);
  gap: 24px;
}

.user-grid-text {
  color: var(--white-white-2, #dfe2e2);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.user-dashboard-line {
  width: 1056px;
  height: 1px;
  background: var(--white-white-8, #3a3d3d);
  opacity: 0.2;
}

.paragraf-black {
  color: black;
}

.login-user-line {
  color: white;
  width: 334px;
  /* border-radius: 82px; */
  border:none;
  padding-left: 20px;
  height: 48px;
  background-color: #51abe9;
}

.login-password-placeholder {
  display: flex;
  align-items: center;
  gap: 323px;
  padding-top: 22px;
}

.register-form-account {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}

.form-buttons {
  display: flex;
  gap: 5px;
}

.form-buttons-gender {
  display: flex;
  gap: 33px;
  width: 531px;
}

.home-paragraf-first {
  display: flex;
  gap: 20px;
}

.home-second-black-banner-element-third-right-one-textbox {
  margin: 0 auto;
  padding: 33px;
}

.home-paragraf-content {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  color: #515151;
  padding: 87px;
  padding-top: 0px;
  max-width: 909px;
}

.sport-text-box {
  max-width: 1000px;
  text-align: justify;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 14px;
}

.clipart-lupa {
  display: block;
}

.cloud-box {
  display: flex;
  width: 100%;
  gap: 88px;
}

.clip-box {
  padding-top: 173px;
  display: flex;
  align-items: center;
  flex-flow: column;
  gap: 83px;
}

.cloud-clip {
  position: relative;
  bottom: 118px;
  left: 68px;
}

.home-first-section-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 338px;
  text-align: left;
}

.elipse-footer {
  position: absolute;
  top: 4182px;
  right: 84%;
  transform: rotate(-51deg);
}

.elipse-green {
  position: absolute;
  width: 20%;
  right: 0%;
  top: 1804px;
}

.logo-footer {
  max-width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* form {
  width: 100%;
  background-color: #252828;
  display: flex;
  flex-flow: column;
  gap: 20px;
} */

/* span {
  color: #04bc4c;
} */

.calculate {
  width: 240px;
  height: 44px;
  background: red;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  color: #ffffff;
}

.calculate-disabled {
  width: 240px;
  height: 44px;
  background: gray;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  color: #ffffff;
}

.reset {
  background-color: #282725;
  color: gray;
  border: none;
}

.button-boy {
  width: 249px;
  background: #151515;
  height: 44px;
  border: none;
}
.button-Millward {
  width: 175px;
  background: black;
  height: 44px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border: none;
}

.login-user-container {
  background: white;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
}

.login-user-paragraf-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5px;
}

.paragraf-account {
  color: red;
  font-size: 15px;
}

.budget {
  margin-top: 50px;
}

.dots-box {
  padding-top: 75px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.login-user-paragraf-span-text {
  color: var(--status-info, #219fff);
  font-family: "poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  text-decoration-line: underline;
}

canvas {
  width: 377px;
}

td {
  border-right: 1px solid var(--white-white-7, #3a3d3d);
  border-bottom: 1px solid var(--white-white-7, #3a3d3d);
  background: #2b2e2e;
  color: var(--white-white-3, #b0b6b6);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-right: none;
  padding: 13px 20px 13px 20px;
}

.legend-element {
  display: flex;
  gap: 5px;
  align-items: center;
}

.legends-text {
  color: var(--white-white-4, #929898);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.gauge-legends-colors {
  display: grid;
  grid: auto / auto auto auto;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 24px;
}

.gauge-span {
  color: var(--white-white-3, #b0b6b6);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.user-result-chart {
  display: flex;
  flex-flow: row;
  gap: 24px;
}

.login-user-button {
  width: 341px;
  height: 44px;
  border: none;
  background: #81d742;
  border-radius: 0px;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  margin-top: 35px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.icons {
  margin-top: 50px;
}

.login-user-header-text {
  text-align: center;
  color:#219fff;
}
.login-user-placeholder-text {
  text-align: left;
  margin-left: 20px;
  font-size: 18px;
  color: #adadad;
}

.login-user-paragraf-container-text {
  color: black;
  text-align: center;
  font-family: "poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td{
  background-color:#51abe9;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  color:white;
  font-weight: bold;
  padding: 8px;
}

.container {
  max-width: 1536px;
  /* padding-right: 145px; */
  padding-right: 122px;
  padding-left: 136px;
  display: flex;
  flex-flow: column;
  align-items: center
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #81d742;
  color: white;
}

.login-user-placeholder {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 323px;
  padding-top: 22px;
}
.login-user-box {
  font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    flex-flow: column;
    display: flex;
    max-width: 435px;
    margin: 0 auto;
    align-items: center;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

.home-special {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  color: #ffffff;
}

.button-solutions-calculate {
  width: 208px;
  height: 51px;
  background: #04bc4c;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  border: none;
  line-height: 20px;
  color: #ffffff;
}

.black-banner-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 59px;
}

.home-black-banner-content {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: 28px;
}

.home-button-black-banner {
  width: 207px;
  height: 54px;
  background: #ff0000;
  box-shadow: 0px 0px 11px #100202;
  border-radius: 10px;
  font-family: "Inter";
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.home-paragraf {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 30px;
  max-width: 352px;
  color: #ffffff;
}

.small-account {
  width: 32px;
}

.register-form-header {
  background: #fff;
  display: flex;
  gap: 82px;
  padding-top: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 348px;
}

.change-data-form-header {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.google-box {
  margin-top: 20px;
}

.register-placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}

.register-form-container {
  padding-top: 0px;
  padding-left: 457px;
  padding-right: 500px;
  text-align: left;
}

.register-form {
  background: #e3e3e3;
  width: 100%;
  padding-top: 21px;
  padding-bottom: 150px;
}

.mission-banner {
  text-align: -webkit-center;
  color: white;
  width: 100%;
}

.home-bar {
  background-color: #282725;
  max-width: 100%;
  text-align: -webkit-center;
  margin: 0 86px 68px;
  margin-top: 258px;
}

.home-bar-text {
  /* width: 1017px; */
  height: 82px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 30px;
}

* {
  box-sizing: border-box;
}

.notebook {
  /* width: 100%; */
}

.gauge-box {
  width: 352px;
  height: 554px;
  border-radius: 12px;
  border: 1px solid var(--white-white-7, #3a3d3d);
  background: #2b2e2e;
  flex-shrink: 0;
}

.gauge-header {
  color: var(--white-white-1, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.gauge-paragraf {
  color: var(--white-white-3, #b0b6b6);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

li {
  list-style-type: none;
}

.calculate-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  color: #ffffff;
}

.footballer {
  width: 100%;
}

a {
  color: #ffffff;
  text-decoration: none;
}

.special-red {
  color: red;
  font-weight: 700;
}

@media only screen and (max-width: 500px) {
  .user-right-box {
    display: flex;
    width: 375px;
    flex-flow: column;
    height: 331px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid var(--white-white-7, #3a3d3d);
    background: var(--white-white-8, #252828);
    gap: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .home-second-black-banner-element-first {
    margin-bottom: 20px;
    flex-flow: row;
  }
  .calculator-btn {
    border-radius: 50px;
    background: var(--primary-primary, #e34646);
    padding: 16px 30px;
    color: var(--white-white-1, #fff);
    text-align: center;
    cursor: pointer;
    width: 252px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .user-field {
    width: 250px;
    height: 56px;
    color: var(--white-white-2, #dfe2e2);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    padding-left: 20px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 100px;
    border: 1.5px solid var(--white-white-7, #3a3d3d);
    background: #252828;
  }
  .user-result-chart {
    flex-flow: column;
  }

  input[type="text"] {
    width: 248px;
    /* width: 334px; */
    height: 36px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-sizing: border-box;
  }

  .sub-right-box {
    display: flex;
    width: 340px;
    flex-flow: column;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 43px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid var(--white-white-7, #3a3d3d);
    background: var(--white-white-8, #252828);
    gap: 24px;
  }

  .user-right-header {
    text-align: left;
    color: var(--white-white-1, #fff);
    font-family: "Inter";
    font-size: 24px;
    padding-left: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }

  .user-right {
    width: 100%;
    border-radius: 0px;
    background: var(--white-white-8, #252828);
    height: unset;
    display: flex;
    padding-bottom: 290px;
    flex-flow: column;
    align-items: baseline;
  }

  .user-right-box {
    display: flex;
    width: 100%;
    flex-flow: column;
    height: 331px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid var(--white-white-7, #3a3d3d);
    background: var(--white-white-8, #252828);
    gap: 24px;
  }

  .user-core {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 48px;
    background-color: #252828;
  }

  .user-grid {
    display: grid;
    grid: auto / auto;
    grid-column-gap: 24px;
    grid-row-gap: 20px;
    padding-bottom: 50px;
    align-items: flex-end;
    padding-left: 24px;
    padding-right: 24px;
  }

  .login-user-button {
    width: 232px;
    height: 44px;
    border: none;
    background: #81d742;
    border-radius: 0px;
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    margin-top: 35px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }

  .sub-element-btn {
    width: 232px;
    padding: 17px 36px;
    cursor: pointer;
    border-radius: 40px;
    color: var(--white-white-1, #fff);
    text-align: center;
    font-family: "Clarity City";
    border: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--primary-primary, #e34646);
  }

  .sub-element {
    width: 291px;
    border-radius: 16px;
    display: flex;
    flex-flow: column;
    padding-left: 30px;
    align-items: baseline;
    background: var(--white-white-1, #fff);
    height: 873px;
  }

  .calculator-first-box {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  /* .home-first-section-element {
    width: 87%;
    height: 347px;
    display: flex;
    flex-flow: column;
    gap: 35px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--white-white-1, #fff);
  } */
  .sub-elements {
    display: flex;
    width: 100%;
    flex-flow: column;
    gap: 30px;
    padding-top: 60px;
    padding-bottom: 60px;
    align-items: center;
    justify-content: center;
  }
  .footer-section-inside {
    display: flex;
    align-items: baseline;
    flex-flow: column;
    padding-bottom: 100px;
    gap: 52px;
  }
  .calculator-boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-top: 50px;
    padding-bottom: 50px;
    /* background-color: #252828; */
    gap: 60px;
  }
  .home-banner-container {
    display: flex;
    width: 100%;
    gap: 60px;
    align-items: center;
    justify-content: flex-end;
    /* padding-bottom: 140px; */
    flex-flow: column;
  }
  .banner-image {
    max-width: 84%;
    height: 300px;
  }
  .home-banner-sport-predictor-main {
    display: flex;
    padding-top: 80px;
    align-items: center;
    flex-flow: column;
    gap: 65px;
    justify-content: center;
  }
  .home-offer-first-section {
    gap: 76px;
  }
  .home-text-section {
    padding: 14px;
  }
  .home-second-black-banner-element-first-right-one-textbox {
    padding-left: 12px;
    padding-right: 0px;
  }

  .home-second-black-banner-element-second-left-one-textbox {
    padding-left: auto;
    padding-top: auto;
    padding-right: auto;
    padding: 20px;
  }

  .container {
    max-width: 640px;
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    max-width: unset;
    margin: 0;
    padding: 0;
  }

  .home-blog-section-elements {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 30px;
    justify-content: center;
    padding-top: 17px;
  }

  .home-banner-sport-predictor-paragraf {
    color: #97a2a9;
    text-align: center;
    font-family: "Clarity City";
    font-size: 18px;
    margin: 0 auto;
    width: 243px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .home-white-section-paragraf {
    color: var(--white-white-8, #252828);
    text-align: center;
    font-family: "Clarity City";
    font-size: 32px;
    font-style: normal;
    margin: 0 auto;
    width: 290px;
    font-weight: 700;
    line-height: 42px;
  }

  .home-laptop-banner-header-text {
    color: var(--white-white-1, #fff);
    font-family: "Clarity City";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin: 0px;
    width: 330px;
    text-align: center;
  }

  .home-laptop-paragraf {
    text-align: left;
    margin: 0px;
    width: 250px;
    color: var(--text, #959f9f);
    font-family: "Clarity City";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .home-laptop-banner-text {
    display: flex;
    flex-flow: column;
    max-width: 600px;
    gap: 20px;
    align-items: center;
  }

  .home-banner-sport-predictor {
    display: flex;
    /* padding-bottom: 110px; */
    background-color: black;
    /* padding-top: 110px; */
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  .notebook {
    width: 50%;
  }

  .home-red-section-paragraf {
    color: var(--white-white-1, #fff);
    text-align: center;
    font-family: "Clarity City";
    font-size: 32px;
    font-style: normal;
    width: 297px;
    font-weight: 700;
    margin: 0 auto;
    line-height: 42px;
  }

  .home-third-section-paragraf {
    color: var(--white-white-8, #252828);
    text-align: center;
    width: 355px;
    font-family: "Clarity City Black";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin: 0 auto;
    line-height: 42px;
  }

  .footballer {
    width: 50%;
  }

  .home-black-banner-list {
    color: var(--text, #959f9f);
    font-family: "Inter";
    font-size: 16px;
    text-align: left;
    flex-flow: column;
    gap: 10px;
    font-style: normal;
    padding: 20px;
    display: flex;
    list-style-type: circle;
    font-weight: 400;
    line-height: 26px;
  }

  .home-black-banner-paragrafs {
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding: 20px;
    /* padding-top: 28px; */
  }

  .home-second-section-paragraf {
    color: white;
    text-align: left;
    font-family: "Clarity City";
    font-size: 32px;
    font-style: normal;
    width: 330px;
    font-weight: 700;
    line-height: 42px;
    margin: 0 auto;
  }

  .home-black-banner-box-inside {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 10px;
  }

  .photo-assemble {
    padding-left: 0px;
  }

  .home-last-banner-box {
    flex-flow: column;
    gap: 17px;
  }

  .foto-home-1 {
    /* max-width: 411px; */
    display: none;
  }

  .home-second-black-banner-element-first {
    /* margin-bottom: 20px; */
    flex-flow: row;
  }

  .home-second-black-banner-element-fourth-left {
    max-width: 437px;
  }

  .home-foto-section {
    display: flex;
    flex-flow: row;
  }

  .children {
    max-width: 100%;
  }

  .home-soccer-banner-left {
    background-color: #282725;
    width: 100%;
    padding-top: 106px;
    padding-left: 0px;
    color: white;
    padding-bottom: 106px;
    display: flex;
    flex-flow: column;
    gap: 38px;
    align-items: center;
  }

  .banner-sport-predictor-right {
    display: none;
  }

  .home-soccer-banner-right {
    display: none;
  }

  .home-banner-second-retangle-element-left {
    padding-left: 242px;
  }

  .login-header-box {
    display: flex;
    gap: 19px;
    align-items: center;
  }

  .home-soccer-banner-right-box {
    display: flex;
    flex-flow: column;
    align-items: baseline;
    padding-left: 0px;
  }

  .soccer-image {
    max-width: 477px;
    height: 465px;
  }

  .line-chart {
    width: 275px;
    margin: 0 auto;
  }

  .home-first-section-paragraf {
    max-width: 335px;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150.5%;
    color: #ffffff;
  }

  .home-first-section-right {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-right: 0px;
    margin: 0 auto;
  }

  .icon-cloud {
    position: relative;
    left: 44%;
    bottom: 120px;
  }

  .cloud-paragraf {
    max-width: 154px;
    text-align: left;
    position: relative;
    left: 30%;
    bottom: 135px;
  }

  .product-text {
    display: flex;
    flex-flow: column;
    gap: 5px;
    padding-top: 20px;
  }

  .cloud-box {
    display: flex;
    width: 100%;
    flex-flow: column;
    margin: 0 auto;
    align-items: center;
    gap: 88px;
  }

  .clip-container {
    display: none;
  }

  .reference-box {
    display: grid;
    justify-content: center;
    align-items: center;
    grid: auto / auto;
    width: 100%;
    grid-column-gap: 50px;
  }

  .clipart-lupa {
    display: none;
  }

  .fa-bars:before,
  .fa-navicon:before {
    content: "\f0c9";
    color: white;
    padding-left: 20px;
  }

  .dropbtn {
    display: block;
    margin-left: 21px;
    width: 160px;
  }

  .krystian-person {
    max-width: 306px;
    padding-right: 6px;
  }

  .mission-text-paragraf {
    text-align: center;
    max-width: 324px;
  }

  .mission-banner-container {
    display: flex;
    width: 100%;
    background-position: bottom;
    gap: 60px;
    background-size: auto;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 140px;
  }

  .mission-banner-text {
    padding-top: 150px;
    display: flex;
    flex-flow: column;
    max-width: 600px;
    margin-left: 69px;
    gap: 20px;
    padding-bottom: 177px;
    align-items: baseline;
  }

  .tablets {
    display: none;
  }

  .fa-user:before {
    content: "\f007";
    padding-left: 15px;
    display: none;
  }

  .login-header-box {
    display: flex;
    max-width: 75px;
    gap: 0px;
    align-items: center;
  }

  .register-form-container {
    padding-top: 107px;
    padding-left: 2px;
    padding-right: 0px;
  }

  .elipse-green {
    display: none;
  }

  .logo {
    max-width: 110px;
    height: 44px;
    margin-left: 4px;
  }

  .home-first-section-header {
    font-style: italic;
    font-weight: 300;
    font-size: 36px;
    padding: 20px;
    text-align: center;
    line-height: 36px;
    max-width: 434px;
  }

  .soccer-banner {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 79%;
    padding-bottom: 50px;
    padding-top: 76px;
    max-width: 560px;
    margin: 0 auto;
  }

  .home-latest-section {
    display: flex;
    gap: 75px;
    flex-flow: column;
    padding-bottom: 149px;
    width: 100%;
    align-items: center;
    padding-top: 100px;
  }

  .mission-text-box {
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 20px;
  }

  .size {
    padding: 10px;
    display: flex;
    justify-content: space-around;
    background: whitesmoke;
    gap: 45px;
  }
  .user-navigation {
    background-color: #282725;
    padding-top: 20px;
    width: 41%;
    height: 370px;
    color: white;
    align-items: center;
    display: flex;
    flex-flow: column;
  }

  .user-data-table {
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding-top: 40px;
    padding-left: 34px;
  }

  .user-data-table-cell {
    display: grid;
    max-width: 400px;
    height: 57px;
    gap: 20px;
    background: #f5f5f5;
    border-radius: 14px;
  }

  .icon-search {
    top: 7%;
    right: 46%;
  }

  .button-home-calculate-left {
    width: 338px;
    color: white;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    height: 54px;
    margin-top: 30px;
    background: red;
    border: none;
  }

  .register-personal-data {
    display: grid;
    grid: auto / auto;
    padding-top: 43px;
    grid-row-gap: 20px;
  }

  .home-banner-header-text {
    font-family: "poppins";
    font-weight: bold;
    font-size: 24px;
    margin: 0px;
    width: 300px;
    line-height: 35px;
    text-align: center;
    color: #ffffff;
    margin-right: 14px;
    font-style: italic;
  }

  .register-placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-left: 20px;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
  }

  .register-form-container {
    margin-top: 107px;
    text-align: left;
    margin: 0 auto;
  }

  .register-form-header {
    background: #fff;
    display: flex;
    gap: 47px;
    padding-left: 72px;
    justify-content: flex-start;
    width: 100%;
  }

  .login-user-line {
    border-top: none;
    border-right: none;
    border-left: none;
    color: white;
    background-color: #51abe9;
    width: 335px;
  }

  .login-user-paragraf-container {
    display: flex;
    justify-content: center;
    gap: 60px;
  }

  .login-user-header-text {
    text-align: center;
  }

  .login-user-placeholder {
    display: flex;
    align-items: center;
    gap: 226px;
  }

  .home-bar {
    background-color: #282725;
    max-width: 100%;
    text-align: -webkit-center;
    margin-top: 0px;
    margin: 0 auto;
  }

  .home-bar-text {
    height: 82px;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    padding: 20px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  .home-banner-paragraf {
    text-align: left;
    color: white;
    margin: 0px;
    width: 311px;
    font-family: "Clarity City";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  /* input[type="submit"] {
    background-color: red;
    padding: 6px 20px;
    width: 202px;
    height: 48px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #ffffff;
    border: none;
    margin-top: 32px;
    border-radius: 4px;
    cursor: pointer;
  } */

  .computer {
    display: none;
  }

  .home {
    margin-top: 0;
  }

  .home-paragraf {
    gap: 32px;
    flex-flow: column;
  }

  .home-paragraf-first {
    flex-flow: column;
  }

  .calculate {
    width: 100%;
  }

  .home-clipart {
    z-index: 100;
  }

  .clipart-box {
    display: grid;
    grid: auto/auto;
    grid-column-gap: 90px;
    margin: 0 auto;
    grid-row-gap: 74px;
    align-items: baseline;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .form-contact {
    text-align: left;
    gap: 24px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .button-boy,
  .button-girl {
    width: 100%;
  }

  .date {
    width: 100%;
  }

  .button-Millward,
  .button-Moore-1,
  .button-Moore-2 {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .home-banner-text {
    padding-top: 150px;
    display: flex;
    flex-flow: column;
    max-width: 600px;
    gap: 20px;
    margin: 0 auto;
    padding-left: 0px;
    padding-bottom: 177px;
    text-align: center;
    align-items: baseline;
  }
  canvas {
    width: 422px;
  }
}

@media screen and (max-width: 1281px) {
  .container {
    /* max-width: 846px; */
  }
  .home-black-banner-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 28px;
  }
  .home-third-section-elements {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    padding-top: 50px;
  }
  .presentations-elements{
    display: grid;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
    grid:auto/auto;
    gap: 114px;
  }
  .home-first-section-elements {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    gap: 30px;
    padding-top: 50px;
  }
}

@media screen and (max-width: 1920px) {
  .soccer-image {
    max-width: 720px;
    height: 465px;
  }

  .home-banner-second-retangle-element-left {
    width: 50%;
    display: flex;
    flex-flow: column;
    align-items: baseline;
    padding-left: 532px;
  }

  .home-soccer-banner-left-header {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    max-width: 200px;
    line-height: 24px;
    color: #ffffff;
  }

  .home-latest-section {
    display: flex;
    gap: 50px;
    flex-flow: column;
    padding-bottom: 149px;
    width: 100%;
    padding-top: 0px;
  }

  .mission-text-box {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 10px;
  }

  .home-special {
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 32px;
    line-height: 35px;
    color: #ffffff;
  }

  /* .home-banner-container {
    display: flex;
    width: 100%;
    gap: 60px;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 140px;
    max-width: 1920px;
  } */

  .romb-clipart {
    max-width: 20%;
    position: absolute;
    left: 66%;
    top: 43%;
  }

  .mission-banner-container {
    display: flex;
    width: 100%;
    background-position: bottom;
    gap: 60px;
    background-size: cover;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 140px;
  }

  .home-second-section-left-header {
    font-style: normal;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 24px;
  }

  .button-home-calculate {
    width: 243px;
    height: 52px;
    background: #51abe9;
    color: white;
    box-shadow: none;
    border: none;
    border-radius: 60px;
    font-family: "poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
  }
}

@media screen and (max-width: 1444px) {
  .home-block-text-banner {
    flex-flow: column;
  }

  /* .user-core {
    display: flex;
    width: 100%;
    flex-flow: column;
    gap: 48px;
    background-color: #252828;
  } */

  .home-first-section-left {
    width: 64%;
    padding: 115px;
    max-height: 484px;
    background-color: #ff0000;
  }

  .banner-sport-predictor-right {
    display: none;
  }

  .retangle-group {
    max-width: 80%;
  }

  .home-first-section-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 58px;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 600px;
    text-align: left;
  }

  .home-soccer-banner-left-header {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    max-width: 200px;
    line-height: 24px;
    color: #ffffff;
  }

  .home-banner-container {
    display: flex;
    width: 100%;
    /* background-image: url("./assets/img/Ellipse\ 1.png"); */
    gap: 60px;
    align-items: baseline;
    flex-flow: column;
    justify-content: flex-end;
    /* padding-left: 385px; */
  }

  .soccer-image {
    max-width: 437px;
    height: 465px;
  }

  .home-soccer-banner-right-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-left: 0px;
  }

  .home-banner-second-retangle-main .container {
    padding: 54px;
  }

  .home-block-text-banner {
    padding-top: 147px;
    display: flex;
    align-items: center;
  }

  .home-banner-second-retangle-element-left {
    padding-left: 336px;
  }

  .home-banner-sport-predictor-left {
    padding-bottom: 20px;
  }

  .mission-text-box {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 20px;
  }

  .boy {
    display: none;
    width: 191.24px;
    height: 258.14px;
    margin-bottom: 193px;
    padding-right: 40px;
  }

  .girl {
    display: none;
    max-height: 353.98px;
    padding-right: 0px;
  }

  .user-data-table {
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding-top: 40px;
    padding-left: 31px;
  }

  .user-data-table-cell {
    display: grid;
    max-width: 600px;
    height: 57px;
    background: #f5f5f5;
    border-radius: 14px;
  }

  .boy {
    display: block;
  }

  .container {
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    position: relative;
  }
}

@media screen and (max-width: 900px) {
  .home-soccer-banner-left {
    padding-left: 0px;
  }
  .home-first-section-left {
    width: 77%;
    padding: 115px;
    max-height: 484px;
    background-color: #ff0000;
  }
  .home-block-text-banner {
    flex-flow: column;
  }
}

@media screen and (max-width: 860px) {
  .container {
    max-width: 835px;
    /* margin: 0 auto; */
  }
  .home-body-card-second-elements {
    display: grid;
    grid: auto / auto auto;
  }

  .home-body-card-elements {
    flex-flow: column;
  }

  .home-first-section-left {
    display: none;
  }

  .home-last-banner-box {
    gap: 20px;
    flex-flow: column;
  }

  .home-second-black-banner-element-fourth-left {
    max-width: 100%;
  }

  .photo-assemble {
    padding-left: 0px;
    flex-flow: column;
  }
  .foto-home {
    /* height: auto;
    max-width: 411px; */
    display: none;
  }
}
