.footer-contact-form {
    margin-top: 10px;
  }
  
  .footer-link-box {
    padding-top: 56px;
  }
  
  .footer-left-box {
    display: flex;
    align-items: baseline;
    gap: 25px;
  }
  
  .footer-icons-container {
    padding-top: 57px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .checkbox-container {
    display: flex;
    justify-content: center;
    gap: 14px;
    max-width: 518px;
  }
  
  .footer-contact-section {
    padding-top: 93px;
    margin-left: auto;
    padding-right: 315px;
  }
  
  .footer-contact-label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 33px;
    color: #ffffff;
  }
  
  .logo_link-footer {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    color: #ffffff;
  }
  
  .footer-checkbox {
    display: flex;
    flex-flow: column;
    align-items: baseline;
    padding-top: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    gap: 20px;
  }
  
  .footer-left-icons {
    display: flex;
    padding-top: 20px;
    gap: 12px;
  }
  
  .footer-left-section {
    display: flex;
    flex-flow: column;
    align-items: baseline;
    width: 100%;
    padding-left: 315px;
  }
  
  .footer {
    background-color: #51abe9;
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-top: 7%;
  }

  .footer-paragraf{
    font-family: 'poppins';
    color: white;
    font-size: 20px;
  }
  
  .footer-box {
    margin: 0 auto;
    padding-bottom: 20px;
    display: flex;
    flex-flow: column;
    padding-top: 20px;
    justify-content: center;
    align-items: unset;
    gap: 20px;
  }
  
  .footer-text {
    max-width: 227px;
    color: var(--white-white-1, #fff);
    font-family: "Clarity City";
    opacity: 0.800000011920929;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  
  .footer-left-link {
    display: flex;
    flex-flow: column;
    padding-top: 29px;
    gap: 20px;
    padding-left: 100px;
    text-align: left;
  }
  
  .footer-contact-input-message {
    width: 293px;
    height: 151px;
    background: #151515;
  }
  
  .footer-contact-label {
    color: white;
  }
  
  .form-contact {
    display: flex;
    flex-flow: column;
    align-items: baseline;
  }
  
  .footer-contact-input {
    height: 36px;
    width: 292px;
    background: #151515;
  }
  
  .footer-contact-submit {
    width: 164px;
    height: 30.85px;
    background: #ff0000;
    border-radius: 5px;
    color: white;
    border: none;
    font-family: "Inter";
    margin-top: 29px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  
  .footer-icons {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 10px;
    padding-right: 166px;
  }
  
  .footer-span {
    color: white;
    font-family: "Clarity City";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 137.02%;
    text-align: center;
  }
  
  .form-field {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  
  .footer-section {
    display: flex;
    width: 100%;
    padding-top: 100px;
    justify-content: center;
  }
  
  .footer-contact-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    text-align: left;
    font-size: 16px;
    color: white;
    line-height: 19px;
  }

  @media screen and (min-width: 900px) {
    .footer-box {
      /* margin: 0 auto; */
      padding-bottom: 20px;
      display: flex;
      flex-flow: row;
      padding-top: 20px;
      justify-content: center;
      align-items: unset;
      gap: 20px;
    }
}
  @media screen and (max-width: 1024px) {
    .footer-left-section {
      padding-left: 0px;
      margin: 0;
    }
  
    .column {
      flex-flow: column;
      align-items: center;
    }
  
    .footer {
      max-width: unset;
      flex-flow: column;
      justify-content: center;
      height: unset;
      /* padding: 2px 132px 132px 70px; */
    }
  
    .footer-left-link {
      margin: 48px 0;
      gap: 32px;
      padding-left: 0px;
    }
  
    .footer-icons {
      max-width: 100%;
      padding-right: 0px;
    }
  
    .footer-icons {
      max-width: 80%;
    }
  
    .footer-section {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-flow: column;
      gap: 35px;
      padding-left: 20px;
    }
  
    .footer-contact-input {
      width: 154px;
    }
  
    .footer-contact-input-message {
      width: 154px;
    }
  
    .footer-contact-section {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-right: 627px;
    }
  }
  