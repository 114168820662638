.header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    overflow-y: visible;
    background-color: #51abe9;
  }

  .fa-bars:before, .fa-navicon:before{
    color:white;
    display: none;
  }
  
  .logo {
    max-width:157px;
    max-height: 32px;
    /* margin-left: 108px; */
  }
  
  .logo-admin {
    width: 94px;
    height: 34px;
  }
  
  .header a.logo {
    font-size: 25px;
    font-weight: bold;
  }
  
  .fa-user:before {
    content: "\f007";
    padding-left: 5px;
    display: block;
  }
  
  .header-nav li {
    white-space: nowrap;
  }

  .menu-box{
    display:flex;
    flex-flow:column
  }

  .menu-list{
    width:'200px';
    color:"black";
    font-size: 10px;
    padding:"10px"
  }
  
  .header-button-contact {
    background-color: #04bc4c;
    border: none;
    color: white;
    padding: 5px 20px 16px 20px;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    width: 114px;
    height: 30px;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    display: inline-block;
  }
  
  .header-reset {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 38px;
    color: #adadad;
  }
  
  /* .header-button-account:focus {
    box-sizing: border-box;
    width: 179px;
    height: 59px;
    border: 1px solid #ffffff;
    border-radius: 47px;
    background-color: #04bc4c;
    color: white;
  } */
  
  .header-link-calc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
  }
  
  .header-link:after {
    content: "/";
    color: white;
    padding-left: 16px;
  }
  
  .header-button-account {
    box-sizing: border-box;
    width: 179px;
    height: 59px;
    border: 1px solid #ffffff;
    /* border-radius: 47px; */
    background-color: #282725;
    color: white;
  }
  
  .header-button-box {
    display: flex;
    /* width: 100%; */
    /* justify-content: flex-end; */
    flex-flow: row;
    gap: 14px;
    align-items: center;
    margin-left: 20px;
  }
  
  .header-link-register {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1920px;
    width: 100%;
  }
  
  .header-link-lite {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
  }
  
  .header-button-login {
    box-sizing: border-box;
    border: none;
    background: #151515;
    font-family: "Inter", sans-serif;
    border-radius: 47px;
    font-style: normal;
    font-weight: 500;
    padding-top: 3px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    height: 43px;
    color: white;
  }
  
  .header-button-logout {
    box-sizing: border-box;
    border: 1px solid #ffffff;
    background: red;
    font-family: "Inter", sans-serif;
    /* border-radius: 47px; */
    width: 131px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    height: 43px;
    color: white;
  }

  .header-button-account {
    box-sizing: border-box;
    border: 1px solid #ffffff;
    background: #81d742;
    font-family: "Inter", sans-serif;
    /* border-radius: 47px; */
    width: 131px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    height: 43px;
    color: white;
  }
  
  .login-header-box {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .header-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
  }

  @media screen and (min-width: 900px) {
    .header-content {
      display: flex;
      gap:15px;
      align-items: center;
      justify-content: center;
      /* max-width: 1920px; */
      padding-left: 208px;
      padding-right: 208px;
      width: 100%;
  }
  .menu-box{
    display:flex;
    flex-flow:row
  }
  .header-nav {
    display: flex;
    align-items: center;
    gap:15px;
    /* padding-left: 70px; */
    max-height: 102px;
    padding-top: 20px;
    width: 100%;
  }
}
  
  @media screen and (max-width: 1200px) {
    .header a {
      text-align: left;
    }
    .fa-bars:before, .fa-navicon:before{
      color:white;
      display: block;
    }
    .header-button-account {
      /* box-sizing: border-box;
      border: 1px solid #ffffff;
      background: #81d742;
      font-family: "Inter", sans-serif;
      border-radius: 47px;
      width: 106px;
      margin-left: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      height: 43px;
      color: white; */
      display: none;
    }
    .header-button-logout {
      box-sizing: border-box;
      border: 1px solid #ffffff;
      background: #81d742;
      font-family: "Inter", sans-serif;
      /* border-radius: 47px; */
      max-width: 106px;
      margin-left: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      height: 43px;
      color: white;
      /* display: none; */
    }
    .header-nav {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    .header-nav {
      display: none;
    }
    .language-box {
      display: none;
    }
    .header-nav.active {
      display: flex;
    }
    .header-button-contact {
      margin-left: auto;
      padding: 11px;
    }
    .header-nav-visible a {
      color: white;
    }
    .szh-menu-container{
      left:"23px!important"
    }
  
    .header-nav-visible {
        align-items: center;
        background-color: #51abe9;
        bottom: 330px;
        display: flex;
        flex-flow: column;
        left: 30%;
        right: 50%;
        padding: 10px;
        position: absolute;
        gap: 8px;
        top: 65px;
        width: 164px;
        z-index: 2;
        height: min-content;
        box-shadow: 0px 0 0px 1px white;
    }
  }
  @media screen and (max-width: 1281px) {
    .header-content {
      max-width: 1204px;
    }
  }
  
  @media screen and (max-width: 1444px) {
    .header-button-contact {
      background-color: #04bc4c;
      border: none;
      color: white;
      padding: 15px 64px;
      text-align: center;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      display: inline-block;
    }
  }
  